


















































































































































































































































































import { Component, Mixins } from 'vue-property-decorator'
import Methods from '@/components/methods'
import { get } from '@/http'
import { alert } from '@/components/pop'

@Component
export default class DemandDetails extends Mixins(Methods) {
	details: any = {}

    created(): void {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
		this.getDemandDetails()
    }
	getDemandDetails(): void {
		get('demand/detail', {
			pdId: this.$route.params.id
        }).then((res: any) => {
            this.details = res.data
        })
	}
	openCoop(): void {
		if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再进行合作申请', state: 1})
            return
        }
		alert({text: '我们已收到您的合作意向，尽快给您对接需求方', state: 1})
	}
	openContact(): void {
		if (sessionStorage.getItem('userId') == null) {
            alert({text: '请登录后再进行联系', state: 1})
            return
        }
		alert({text: '我们会尽快为您联系，请稍后', state: 1})
	}
}
